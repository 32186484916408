
// Font Links:

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')// Colors:

// Colors:

$brightBlue: #007ACC
$lightGray: #F1F1F1
$darkGray: #333333

// Margins:

$m128: clamp(64px,6.66624vw,256px)
$m64: clamp(32px,3.33312vw,128px)
$m32: clamp(16px,1.66656vw,64px)
$m16: clamp(8px,0.83328vw,32px)
$m8: clamp(4px,0.41664vw,16px)

$m128mob: clamp(64px,29.90592vw,256px)
$m64mob: clamp(32px,14.95296vw,128px)
$m32mob: clamp(16px,7.47648vw,64px)
$m16mob: clamp(8px,3.73824vw,32px)
$m8mob: clamp(4px,1.86912vw,16px)

// Fonts:

$f48: clamp(24px,2.49984vw,96px)
$f36: clamp(18px,1.87488vw,72px)
$f28: clamp(14px,1.45824vw,56px)
$f25: clamp(12.5px,1.302vw,50px)
$f24: clamp(12px,1.24992vw,48px)
$f22: clamp(11px,1.1457600000000001vw,44px)
$f21: clamp(10.5px,1.09368vw,42px)
$f16: clamp(8px,0.83328vw,32px)

// Percents:

$container: 86.45833333333333%

*
    margin: 0
    padding: 0
    box-sizing: border-box

    body
        width: 100%
        height: auto

        .footer
            width: 100%
            height: auto
            background: $brightBlue
            margin-top: $m64mob

            .footer-container
                width: $container
                height: auto
                margin: auto
                display: flex
                flex-direction: column
                align-items: center

                .logo
                    width: clamp(125px,64.102vw, 500px)
                    margin-top: $m64mob

            .links
                width: 100%
                height: auto
                display: flex
                flex-direction: column
                align-items: center
                margin-top: $m32mob
                margin-bottom: $m32mob

                .link
                    color: $lightGray
                    font-family: Raleway
                    font-size: clamp(10.5px,4.90644vw,42px)
                    font-style: normal
                    font-weight: 600
                    line-height: normal
                    margin-top: $m16mob

            .request
                width: 100%
                height: clamp(28px,13.083839999999999vw,112px)
                border: none
                margin-top: $m32mob
                border-radius: 4px
                color: $brightBlue
                font-family: Raleway
                font-size: clamp(12px,5.60736vw,48px)
                font-style: normal
                font-weight: 700
                line-height: normal

                .line
                    width: 100%
                    height: 1px
                    background-color: $lightGray
                    margin-top: $m32mob

                .rights
                    font-size: clamp(9.5px,4.439159999999999vw,38px)
                    color: $lightGray

            .footer-pc
                display: none

@media screen and (min-width: 600px) and (max-width: 1024px)

    *

        body
            width: 100%
            height: auto

            .request
                width: clamp(225px,50.61867266591676vw,900px) !important
                font-size: clamp(19.5px,4.386951631046119vw,78px) !important

@media screen and  (min-width: 1200px)

    *

        body
            width: 100%
            height: auto

            .footer
                width: 100%
                height: auto
                background: $brightBlue
                margin-top: $m64mob

                .footer-container
                    display: none

                .footer-pc
                    width: $container
                    display: flex
                    flex-direction: column
                    margin: auto

                    .links-container
                        width: 100%
                        display: flex
                        flex-direction: row
                        align-items: center
                        justify-content: space-between
                        margin-top: $m64

                        .logo
                            width: clamp(138.5px,14.426160000000001vw,554px)

                        .white
                            color: $lightGray
                            font-family: Raleway
                            font-size: $f28
                            font-style: normal
                            font-weight: 800
                            line-height: normal

                        .links
                            display: flex
                            flex-direction: row
                            align-items: center
                            justify-content: space-between
                            cursor: pointer
                            margin-left: clamp(200px,20.833333333333332vw,800px)

                            .links-cont
                                margin-right: $m64

                                .link
                                    color: $lightGray
                                    font-family: Raleway
                                    font-size: clamp(9px,0.93744vw,36px)
                                    font-style: normal
                                    font-weight: 600
                                    line-height: normal
                                    margin-left: $m32

                            .request-button
                                width: clamp(142.5px,14.8428vw,570px)
                                height: clamp(23px,2.39568vw,92px)
                                background-color: $lightGray
                                color: $brightBlue
                                border-radius: 6px
                                border: none
                                font-family: Raleway
                                font-size: clamp(9px,0.93744vw,36px)
                                font-style: normal
                                font-weight: 700
                                line-height: normal
                                transition: 0.3s all
                                &:hover
                                    background-color: #E1E1E1

                    .line
                        width: 100%
                        height: 2px
                        background-color: $lightGray
                        margin-top: $m32

                    .rights
                        color: rgba(255, 255, 255, 0.60)
                        font-family: Raleway
                        font-size: $f21
                        font-style: normal
                        font-weight: 400
                        line-height: normal
                        margin: auto
                        color: $lightGray
                        margin-top: $m32
                        margin-bottom: $m32
