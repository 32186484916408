
// Font Links:

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')// Colors:

// Colors:

$brightBlue: #007ACC
$lightGray: #F1F1F1
$darkGray: #333333

// Margins:

$m128: clamp(64px,6.66624vw,256px)
$m64: clamp(32px,3.33312vw,128px)
$m32: clamp(16px,1.66656vw,64px)
$m16: clamp(8px,0.83328vw,32px)
$m8: clamp(4px,0.41664vw,16px)

$m128mob: clamp(64px,29.90592vw,256px)
$m64mob: clamp(32px,14.95296vw,128px)
$m32mob: clamp(16px,7.47648vw,64px)
$m16mob: clamp(8px,3.73824vw,32px)
$m8mob: clamp(4px,1.86912vw,16px)

// Fonts:

$f48: clamp(24px,2.49984vw,96px)
$f36: clamp(18px,1.87488vw,72px)
$f28: clamp(14px,1.45824vw,56px)
$f25: clamp(12.5px,1.302vw,50px)
$f24: clamp(12px,1.24992vw,48px)
$f22: clamp(11px,1.1457600000000001vw,44px)
$f21: clamp(10.5px,1.09368vw,42px)
$f16: clamp(8px,0.83328vw,32px)

// Percents:

$container: 86.45833333333333%

*
    margin: 0
    padding: 0
    box-sizing: border-box

    body
        width: 100%
        height: auto

        .requirements-screen
            margin-top: clamp(50px,23.363999999999997vw,200px)

            .container-box
                width: $container
                height: auto
                display: flex
                flex-direction: column
                align-items: center
                justify-content: center
                margin: auto

                .heading
                    color: $darkGray
                    text-align: center
                    margin: auto
                    margin-top: $m32mob
                    font-family: Raleway
                    font-size: clamp(12px,5.60736vw,48px)
                    font-style: normal
                    font-weight: 800
                    line-height: 140.4%
                    text-transform: uppercase

                .star
                    margin-top: $m32mob

                .paragraph
                    color: #333
                    text-align: center
                    font-family: "Open Sans"
                    font-size: clamp(7px,3.2709599999999996vw,28px)
                    font-style: normal
                    font-weight: 400
                    line-height: normal
                    margin-top: $m32mob

                .box
                    width: 100%
                    height: auto
                    border-radius: 10px
                    background: #F1F1F1
                    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.10)
                    display: flex
                    flex-direction: column
                    align-items: center
                    margin-top: $m64mob

                    .box-heading-c
                        display: flex
                        flex-direction: column
                        align-items: center

                        .number-container
                            width: clamp(21px,9.81288vw,84px)
                            height: clamp(21px,9.81288vw,84px)
                            background-color: $brightBlue
                            border-radius: 100px
                            margin-top: $m32mob

                            .number
                                color: #FFF
                                text-align: center
                                font-family: "Open Sans"
                                margin-top: clamp(2px,0.93456vw,8px)
                                font-size: clamp(12px,5.60736vw,48px)
                                font-style: normal
                                font-weight: 600
                                line-height: normal

                        .box-heading
                            color: $darkGray
                            text-align: center
                            font-family: "Open Sans"
                            margin-top: $m8mob
                            font-size: clamp(9px,4.20552vw,36px)
                            font-style: normal
                            font-weight: 600
                            line-height: normal

                    .box-text
                        width: 70%
                        font-family: Open Sans
                        font-size: clamp(8.5px,3.9718799999999996vw,34px)
                        font-style: normal
                        text-align: center
                        font-weight: 400
                        margin-top: $m16mob
                        margin-bottom: $m32mob
                        line-height: normal

                .request
                    width: clamp(145px,67.7556vw,580px)
                    height: clamp(28px,13.083839999999999vw,112px)
                    background-color: $brightBlue
                    color: #FFF
                    font-family: Raleway
                    font-size: $m16mob
                    font-style: normal
                    font-weight: 700
                    border: none
                    border-radius: 4px
                    margin-top: $m64mob
                    line-height: normal

            .container-box-pc
                display: none

@media screen and  (min-width: 1200px)

    *

        body
            width: 100%
            height: auto

            .requirements-screen
                width: 100%
                height: auto
                margin-top: clamp(128px,13.33248vw,512px)

                .container-box
                    display: none

                .container-box-pc
                    width: $container
                    height: auto
                    display: flex
                    margin: auto
                    flex-direction: column

                    .heading
                        width: 50%
                        margin: auto
                        font-size: $f36
                        text-align: center
                        color: $darkGray

                    .tab
                        display: flex
                        flex-direction: column
                        align-items: center
                        margin-top: $m64

                        .tab-text
                            width: 60%
                            color: $darkGray
                            text-align: center
                            margin-top: $m32
                            font-family: Open Sans
                            font-size: $f22
                            font-style: normal
                            font-weight: 400
                            line-height: normal

                    .boxes
                        width: 100%
                        display: flex
                        flex-direction: row
                        justify-content: space-between
                        align-items: center
                        margin-top: $m64

                        .box
                            width: clamp(255px,26.5608vw,1020px)
                            height: clamp(108.5px,11.30136vw,434px)
                            border-radius: 10px
                            background: #F1F1F1
                            box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.10)

                            .box-heading-c
                                display: flex
                                flex-direction: row
                                align-items: center
                                margin-left: $m16
                                margin-top: $m16

                                .number-container
                                    width: clamp(25.5px,2.65608vw,102px)
                                    height: clamp(25.5px,2.65608vw,102px)
                                    display: flex
                                    align-items: center
                                    justify-content: center
                                    background-color: $brightBlue
                                    border-radius: 100px

                                    .number
                                        color: #FFF
                                        text-align: center
                                        font-family: Open Sans
                                        font-size: $f25
                                        font-style: normal
                                        font-weight: 600
                                        line-height: normal
                                        margin-top: 8px

                                .box-heading
                                    color: $darkGray
                                    text-align: center
                                    font-family: Open Sans
                                    font-size: clamp(12px,1.24992vw,48px)
                                    font-style: normal
                                    font-weight: 600
                                    line-height: normal
                                    margin-left: $m16

                            .box-text
                                width: 80%
                                margin: auto
                                text-align: left
                                margin-left: $m16
                                margin-top: $m32
                                color: $darkGray
                                font-family: Open Sans
                                font-size: $f22
                                font-style: normal
                                font-weight: 400
                                line-height: normal

                    .request
                        width: clamp(142.5px,14.8428vw,570px)
                        height: clamp(28px,2.91648vw,112px)
                        border-radius: 6px
                        background: $brightBlue
                        color: #FFF
                        font-family: Raleway
                        font-size: $f24
                        border: none
                        font-style: normal
                        font-weight: 700
                        line-height: normal
                        margin-top: $m64 !important
                        margin: auto
                        transition: 0.3s all
                        &:hover
                            background: #0069AF
