@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
* body {
  width: 100%;
  height: auto;
}
* body .footer {
  width: 100%;
  height: auto;
  background: #007ACC;
  margin-top: clamp(32px, 14.95296vw, 128px);
}
* body .footer .footer-container {
  width: 86.4583333333%;
  height: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
* body .footer .footer-container .logo {
  width: clamp(125px, 64.102vw, 500px);
  margin-top: clamp(32px, 14.95296vw, 128px);
}
* body .footer .links {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: clamp(16px, 7.47648vw, 64px);
  margin-bottom: clamp(16px, 7.47648vw, 64px);
}
* body .footer .links .link {
  color: #F1F1F1;
  font-family: Raleway;
  font-size: clamp(10.5px, 4.90644vw, 42px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: clamp(8px, 3.73824vw, 32px);
}
* body .footer .request {
  width: 100%;
  height: clamp(28px, 13.08384vw, 112px);
  border: none;
  margin-top: clamp(16px, 7.47648vw, 64px);
  border-radius: 4px;
  color: #007ACC;
  font-family: Raleway;
  font-size: clamp(12px, 5.60736vw, 48px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
* body .footer .request .line {
  width: 100%;
  height: 1px;
  background-color: #F1F1F1;
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* body .footer .request .rights {
  font-size: clamp(9.5px, 4.43916vw, 38px);
  color: #F1F1F1;
}
* body .footer .footer-pc {
  display: none;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  * body {
    width: 100%;
    height: auto;
  }
  * body .request {
    width: clamp(225px, 50.6186726659vw, 900px) !important;
    font-size: clamp(19.5px, 4.386951631vw, 78px) !important;
  }
}
@media screen and (min-width: 1200px) {
  * body {
    width: 100%;
    height: auto;
  }
  * body .footer {
    width: 100%;
    height: auto;
    background: #007ACC;
    margin-top: clamp(32px, 14.95296vw, 128px);
  }
  * body .footer .footer-container {
    display: none;
  }
  * body .footer .footer-pc {
    width: 86.4583333333%;
    display: flex;
    flex-direction: column;
    margin: auto;
  }
  * body .footer .footer-pc .links-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: clamp(32px, 3.33312vw, 128px);
  }
  * body .footer .footer-pc .links-container .logo {
    width: clamp(138.5px, 14.42616vw, 554px);
  }
  * body .footer .footer-pc .links-container .white {
    color: #F1F1F1;
    font-family: Raleway;
    font-size: clamp(14px, 1.45824vw, 56px);
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  * body .footer .footer-pc .links-container .links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-left: clamp(200px, 20.8333333333vw, 800px);
  }
  * body .footer .footer-pc .links-container .links .links-cont {
    margin-right: clamp(32px, 3.33312vw, 128px);
  }
  * body .footer .footer-pc .links-container .links .links-cont .link {
    color: #F1F1F1;
    font-family: Raleway;
    font-size: clamp(9px, 0.93744vw, 36px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: clamp(16px, 1.66656vw, 64px);
  }
  * body .footer .footer-pc .links-container .links .request-button {
    width: clamp(142.5px, 14.8428vw, 570px);
    height: clamp(23px, 2.39568vw, 92px);
    background-color: #F1F1F1;
    color: #007ACC;
    border-radius: 6px;
    border: none;
    font-family: Raleway;
    font-size: clamp(9px, 0.93744vw, 36px);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    transition: 0.3s all;
  }
  * body .footer .footer-pc .links-container .links .request-button:hover {
    background-color: #E1E1E1;
  }
  * body .footer .footer-pc .line {
    width: 100%;
    height: 2px;
    background-color: #F1F1F1;
    margin-top: clamp(16px, 1.66656vw, 64px);
  }
  * body .footer .footer-pc .rights {
    color: rgba(255, 255, 255, 0.6);
    font-family: Raleway;
    font-size: clamp(10.5px, 1.09368vw, 42px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: auto;
    color: #F1F1F1;
    margin-top: clamp(16px, 1.66656vw, 64px);
    margin-bottom: clamp(16px, 1.66656vw, 64px);
  }
}/*# sourceMappingURL=footer-styles.css.map */