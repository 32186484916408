
// Font Links:

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')// Colors:

// Colors:

$brightBlue: #007ACC
$lightGray: #F1F1F1
$darkGray: #333333

// Margins:

$m128: clamp(64px,6.66624vw,256px)
$m64: clamp(32px,3.33312vw,128px)
$m32: clamp(16px,1.66656vw,64px)
$m16: clamp(8px,0.83328vw,32px)
$m8: clamp(4px,0.41664vw,16px)

$m128mob: clamp(64px,29.90592vw,256px)
$m64mob: clamp(32px,3.33312vw,128px)
$m32mob: clamp(16px,7.47648vw,64px)
$m16mob: clamp(8px,3.73824vw,32px)
$m8mob: clamp(4px,1.86912vw,16px)

// Fonts:

$f48: clamp(24px,2.49984vw,96px)
$f36: clamp(18px,1.87488vw,72px)
$f28: clamp(14px,1.45824vw,56px)
$f25: clamp(12.5px,1.302vw,50px)
$f24: clamp(12px,1.24992vw,48px)
$f22: clamp(11px,1.1457600000000001vw,44px)
$f21: clamp(10.5px,1.09368vw,42px)
$f16: clamp(8px,0.83328vw,32px)

// Percents:

$container: 86.45833333333333%

*
    margin: 0
    padding: 0
    box-sizing: border-box

    body
        width: 100%
        height: auto

        .mob-nav
            width: $container
            margin: auto
            margin-top: $m64mob

            .logo
                width: clamp(100px,46.727999999999994vw,400px)

        .pc-nav
            display: none

@media screen and (min-width: 600px) and (max-width: 1024px)

    *
        margin: 0
        padding: 0
        box-sizing: border-box

        body
            width: 100%
            height: auto
            overflow-x: hidden !important

            .bm-burger-bars
                background: #032727 !important

            .bm-menu-wrap
                width: clamp(300px,67.49156355455568vw,1200px) !important

            .bm-item-list
                font-size: clamp(21px,4.724409448818897vw,84px) !important

            .bm-item
                margin-right: clamp(20px,4.499437570303712vw,80px)

            .bm-cross-button
                width: clamp(46px,10.348706411698538vw,184px) !important
                height: clamp(46px,10.348706411698538vw,184px) !important
                display: flex !important
                left: clamp(230px,51.74353205849269vw,920px) !important
                align-items: center !important
                justify-content: center !important

            .bm-cross
                width: 4px !important
                height: clamp(30px,6.749156355455568vw,120px) !important
                margin-left: -30px !important

            .mob-nav
                width: $container
                margin: auto
                margin-top: clamp(32px,7.199100112485939vw,128px)

                .logo
                    width: 400px

@media screen and  (min-width: 1200px)

    *

        .nav
            width: 100%
            height: auto

            .mob-nav
                display: none

            .pc-nav
                width: 100%
                height: auto
                z-index: 1000
                position: fixed
                display: flex
                background-color: white
                box-shadow: 0px 10px 55px 0px rgba(0, 0, 0, 0.03)

                .pc-nav-container
                    width: $container
                    height: auto
                    margin: auto
                    display: flex
                    align-items: center
                    justify-content: space-between
                    margin-top: clamp(22.5px,2.3436vw,90px)
                    margin-bottom: clamp(22.5px,2.3436vw,90px)

                    .logo
                        color: $brightBlue
                        font-family: "Raleway"
                        font-size: clamp(14px,1.45824vw,56px)
                        font-style: normal
                        font-weight: 800
                        width: clamp(138.5px,14.426160000000001vw,554px)
                        line-height: normal

                    .links-container
                        display: flex
                        align-items: center

                        .links
                            display: flex
                            align-items: center
                            margin-right: $m64

                            .link
                                color: $darkGray
                                font-family: Raleway
                                font-size: clamp(9px,0.93744vw,36px)
                                font-style: normal
                                font-weight: 600
                                line-height: normal
                                margin-right: $m32
                                transition: 0.3s all
                                cursor: pointer
                                &:hover
                                    color: $brightBlue

                            .results
                                margin-right: 0px

                        .request-btn
                            width: clamp(100px,10.416vw,400px)
                            height: clamp(22.5px,2.3436vw,90px)
                            border: none
                            border-radius: 6px
                            background: $brightBlue
                            color: #FFF
                            font-family: "Raleway"
                            font-size: clamp(9px,0.93744vw,36px)
                            font-style: normal
                            font-weight: 700
                            line-height: normal
                            transition: 0.3s all
                            &:hover
                                background: #0069AF
