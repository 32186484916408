@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
* body {
  width: 100%;
  height: auto;
}
* body .results-screen {
  margin-top: clamp(50px, 23.364vw, 200px);
}
* body .results-screen .container {
  width: 86.4583333333%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
* body .results-screen .container .additional-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
* body .results-screen .container .additional-text .mini-text {
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: "Raleway", sans-serif;
}
* body .results-screen .container .additional-text .line {
  width: 100%;
  height: 1px;
  background-color: #007ACC;
  margin-top: 0px;
}
* body .results-screen .container .heading {
  text-align: center;
}
* body .results-screen .container .star-tab {
  width: clamp(178.5px, 83.40948vw, 714px) !important;
  height: clamp(108.5px, 50.69988vw, 434px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #F1F1F1;
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* body .results-screen .container .star-tab .star-text {
  color: #333333;
  text-align: center;
  font-family: Open Sans;
  font-size: clamp(10.5px, 4.90644vw, 42px);
  font-style: normal;
  font-weight: 600;
  margin-top: clamp(8px, 3.73824vw, 32px);
}
* body .results-screen .container .between-line {
  width: 100%;
  height: 1px;
  background-color: #007ACC;
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* body .results-screen .container .star-tab-m {
  width: 100%;
  height: clamp(157.5px, 73.5966vw, 630px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #F1F1F1;
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* body .results-screen .container .star-tab-m .star-text {
  width: 80%;
  color: #333333;
  text-align: center;
  font-family: Open Sans;
  font-size: clamp(10.5px, 4.90644vw, 42px);
  font-style: normal;
  font-weight: 600;
  margin-top: clamp(8px, 3.73824vw, 32px);
}
* body .results-screen .container .star-tab-b {
  width: 100%;
  height: clamp(164.5px, 76.86756vw, 658px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #F1F1F1;
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* body .results-screen .container .star-tab-b .star-text {
  width: 90%;
  color: #333333;
  text-align: center;
  font-family: Open Sans;
  font-size: clamp(10.5px, 4.90644vw, 42px);
  font-style: normal;
  font-weight: 600;
  margin-top: clamp(8px, 3.73824vw, 32px);
}
* body .results-screen .container-box-c {
  display: none;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  * body {
    width: 100%;
    height: auto;
  }
  * body .mini-text {
    color: #007ACC;
  }
}
@media screen and (min-width: 1200px) {
  * body {
    width: 100%;
    height: auto;
  }
  * body .results-screen {
    width: 100%;
    height: auto;
    margin-top: clamp(125px, 13.02vw, 500px);
  }
  * body .results-screen .container {
    display: none;
  }
  * body .results-screen .container-box-c {
    width: 86.4583333333%;
    height: auto;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  * body .results-screen .container-box-c .additional-text {
    display: flex;
    align-items: center;
  }
  * body .results-screen .container-box-c .additional-text .mini-text {
    color: #007ACC;
    font-family: Raleway;
    font-size: clamp(10.5px, 1.09368vw, 42px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: clamp(8px, 0.83328vw, 32px);
  }
  * body .results-screen .container-box-c .additional-text .line {
    width: clamp(1.5px, 0.15624vw, 6px);
    height: clamp(27px, 2.81232vw, 108px);
    background-color: #007ACC;
  }
  * body .results-screen .container-box-c .heading {
    width: 30%;
    color: #333333;
    text-align: center;
    font-family: Raleway;
    font-size: clamp(18px, 1.87488vw, 72px);
    font-style: normal;
    font-weight: 800;
    line-height: 140.4%;
    text-transform: uppercase;
    margin-top: clamp(16px, 1.66656vw, 64px);
  }
  * body .results-screen .container-box-c .row-one, * body .results-screen .container-box-c .row-two, * body .results-screen .container-box-c .row-three {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: clamp(32px, 3.33312vw, 128px);
  }
  * body .results-screen .container-box-c .row-one .star-tab, * body .results-screen .container-box-c .row-two .star-tab, * body .results-screen .container-box-c .row-three .star-tab {
    width: clamp(266px, 27.70656vw, 1064px);
    height: clamp(108.5px, 11.30136vw, 434px);
    border-radius: 10px;
    background: #F1F1F1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.06);
  }
  * body .results-screen .container-box-c .row-one .star-tab .star-text, * body .results-screen .container-box-c .row-two .star-tab .star-text, * body .results-screen .container-box-c .row-three .star-tab .star-text {
    color: #333333;
    text-align: center;
    font-family: Open Sans;
    font-size: clamp(12.5px, 1.302vw, 50px);
    font-style: normal;
    font-weight: 600;
    line-height: 140.4%;
    margin-top: clamp(16px, 1.66656vw, 64px);
  }
  * body .results-screen .container-box-c .row-one .star-tab-m, * body .results-screen .container-box-c .row-two .star-tab-m, * body .results-screen .container-box-c .row-three .star-tab-m {
    width: clamp(266px, 27.70656vw, 1064px);
    height: clamp(120.5px, 12.55128vw, 482px);
    border-radius: 10px;
    background: #F1F1F1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.06);
  }
  * body .results-screen .container-box-c .row-one .star-tab-m .star-text, * body .results-screen .container-box-c .row-two .star-tab-m .star-text, * body .results-screen .container-box-c .row-three .star-tab-m .star-text {
    width: 70%;
    color: #333333;
    text-align: center;
    font-family: Open Sans;
    font-size: clamp(11px, 1.14576vw, 44px);
    font-style: normal;
    font-weight: 600;
    line-height: 140.4%;
    margin-top: clamp(16px, 1.66656vw, 64px);
  }
  * body .results-screen .container-box-c .row-one .star-tab-b, * body .results-screen .container-box-c .row-two .star-tab-b, * body .results-screen .container-box-c .row-three .star-tab-b {
    width: clamp(266px, 27.70656vw, 1064px);
    height: clamp(146px, 15.20736vw, 584px);
    border-radius: 10px;
    background: #F1F1F1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.06);
  }
  * body .results-screen .container-box-c .row-one .star-tab-b .star-text, * body .results-screen .container-box-c .row-two .star-tab-b .star-text, * body .results-screen .container-box-c .row-three .star-tab-b .star-text {
    width: 80%;
    color: #333333;
    text-align: center;
    font-family: Open Sans;
    font-size: clamp(11px, 1.14576vw, 44px);
    font-style: normal;
    font-weight: 600;
    line-height: 140.4%;
    margin-top: clamp(16px, 1.66656vw, 64px);
  }
  * body .results-screen .container-box-c .line-between {
    width: 100%;
    height: 3px;
    background: rgba(0, 122, 204, 0.5);
    margin-top: clamp(32px, 3.33312vw, 128px);
  }
}/*# sourceMappingURL=results-styles.css.map */