@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
* body {
  width: 100%;
  height: auto;
}
* body .a-paragraph {
  margin-top: clamp(7px, 3.27096vw, 28px);
  margin-left: clamp(4px, 1.86912vw, 16px);
  font-size: clamp(7.5px, 3.5046vw, 30px);
  text-align: left;
}
* body .about-screen {
  margin-top: clamp(50px, 23.364vw, 200px);
}
* body .about-screen .container-box {
  width: 86.4583333333%;
  margin: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
}
* body .about-screen .container-box .additional-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
* body .about-screen .container-box .additional-text .mini-text {
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: "Raleway", sans-serif;
}
* body .about-screen .container-box .additional-text .line {
  width: 100%;
  height: 1px;
  background-color: #007ACC;
  margin-top: clamp(8px, 3.73824vw, 32px);
}
* body .about-screen .container-box .heading {
  color: #333333;
  text-align: center;
  margin: auto;
  margin-top: clamp(16px, 7.47648vw, 64px);
  font-family: Raleway;
  font-size: clamp(12px, 5.60736vw, 48px);
  font-style: normal;
  font-weight: 800;
  line-height: 140.4%;
  text-transform: uppercase;
}
* body .about-screen .container-box .tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: clamp(32px, 14.95296vw, 128px);
  text-align: center;
}
* body .about-screen .container-box .tabs .tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* body .about-screen .container-box .tabs .tab .tab-text {
  margin-top: clamp(8px, 3.73824vw, 32px);
  color: #333;
  text-align: center;
  font-family: Open Sans;
  font-size: clamp(7.5px, 3.5046vw, 30px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
* body .about-screen .container-box .accordions {
  display: flex;
  flex-direction: column;
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* body .about-screen .container-box .accordions .accordion-item {
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* body .about-screen .container-box-pc {
  display: none;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  * body {
    width: 100%;
    height: auto;
  }
  * body .a-paragraph {
    margin-top: clamp(7px, 3.27096vw, 28px);
    margin-left: clamp(4px, 1.86912vw, 16px);
    text-align: left;
  }
  * body .accordion-body {
    font-size: clamp(13.5px, 3.03712036vw, 54px) !important;
  }
  * body .additional-text .mini-text {
    font-size: clamp(16px, 3.5995500562vw, 64px) !important;
  }
}
@media screen and (min-width: 1200px) {
  * body {
    width: 100%;
    height: auto;
  }
  * body .accordion-item {
    width: clamp(348px, 36.24768vw, 1392px);
    border: none;
    border-bottom: 2px solid #007ACC;
  }
  * body .accordion-item .accordion-body {
    font-size: clamp(10px, 1.0416vw, 40px);
  }
  * body .accordion-item .a-paragraph {
    color: #007ACC;
    font-family: Open Sans;
    font-size: clamp(10.5px, 1.09368vw, 42px) !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  * body .about-screen {
    width: 100%;
    height: auto;
  }
  * body .about-screen .container-box {
    display: none;
  }
  * body .about-screen .container-box-pc {
    width: 86.4583333333%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }
  * body .about-screen .container-box-pc .additional-text {
    display: flex;
    align-items: center;
  }
  * body .about-screen .container-box-pc .additional-text .mini-text {
    color: #007ACC;
    font-family: Raleway;
    font-size: clamp(10.5px, 1.09368vw, 42px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: clamp(8px, 0.83328vw, 32px);
  }
  * body .about-screen .container-box-pc .additional-text .line {
    width: clamp(1.5px, 0.15624vw, 6px);
    height: clamp(27px, 2.81232vw, 108px);
    background-color: #007ACC;
  }
  * body .about-screen .container-box-pc .heading {
    width: 50%;
    text-align: center;
    margin-top: clamp(32px, 3.33312vw, 128px);
    font-family: Raleway;
    font-size: clamp(18px, 1.87488vw, 72px);
    font-style: normal;
    font-weight: 800;
    line-height: 140.4%;
    text-transform: uppercase;
  }
  * body .about-screen .container-box-pc .tabs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  * body .about-screen .container-box-pc .tabs .tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: clamp(32px, 3.33312vw, 128px);
  }
  * body .about-screen .container-box-pc .tabs .tab .tab-text {
    width: 80%;
    margin-top: clamp(8px, 0.83328vw, 32px);
    color: #000;
    text-align: center;
    font-family: "Open Sans";
    font-size: clamp(11px, 1.14576vw, 44px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  * body .about-screen .container-box-pc .accordions {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: clamp(32px, 3.33312vw, 128px);
  }
  * body .about-screen .container-box-pc .accordions .row-one {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: clamp(32px, 3.33312vw, 128px);
  }
  * body .about-screen .container-box-pc .accordions .row-two {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: clamp(64px, 6.66624vw, 256px);
  }
  * body .about-screen .container-box-pc .accordions .row-three {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
    margin-top: clamp(64px, 6.66624vw, 256px);
  }
}/*# sourceMappingURL=about-styles.css.map */