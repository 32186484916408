@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
* body {
  width: 100%;
  height: auto;
}
* body .mob-nav {
  width: 86.4583333333%;
  margin: auto;
  margin-top: clamp(32px, 3.33312vw, 128px);
}
* body .mob-nav .logo {
  width: clamp(100px, 46.728vw, 400px);
}
* body .pc-nav {
  display: none;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  * body {
    width: 100%;
    height: auto;
    overflow-x: hidden !important;
  }
  * body .bm-burger-bars {
    background: #032727 !important;
  }
  * body .bm-menu-wrap {
    width: clamp(300px, 67.4915635546vw, 1200px) !important;
  }
  * body .bm-item-list {
    font-size: clamp(21px, 4.7244094488vw, 84px) !important;
  }
  * body .bm-item {
    margin-right: clamp(20px, 4.4994375703vw, 80px);
  }
  * body .bm-cross-button {
    width: clamp(46px, 10.3487064117vw, 184px) !important;
    height: clamp(46px, 10.3487064117vw, 184px) !important;
    display: flex !important;
    left: clamp(230px, 51.7435320585vw, 920px) !important;
    align-items: center !important;
    justify-content: center !important;
  }
  * body .bm-cross {
    width: 4px !important;
    height: clamp(30px, 6.7491563555vw, 120px) !important;
    margin-left: -30px !important;
  }
  * body .mob-nav {
    width: 86.4583333333%;
    margin: auto;
    margin-top: clamp(32px, 7.1991001125vw, 128px);
  }
  * body .mob-nav .logo {
    width: 400px;
  }
}
@media screen and (min-width: 1200px) {
  * .nav {
    width: 100%;
    height: auto;
  }
  * .nav .mob-nav {
    display: none;
  }
  * .nav .pc-nav {
    width: 100%;
    height: auto;
    z-index: 1000;
    position: fixed;
    display: flex;
    background-color: white;
    box-shadow: 0px 10px 55px 0px rgba(0, 0, 0, 0.03);
  }
  * .nav .pc-nav .pc-nav-container {
    width: 86.4583333333%;
    height: auto;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: clamp(22.5px, 2.3436vw, 90px);
    margin-bottom: clamp(22.5px, 2.3436vw, 90px);
  }
  * .nav .pc-nav .pc-nav-container .logo {
    color: #007ACC;
    font-family: "Raleway";
    font-size: clamp(14px, 1.45824vw, 56px);
    font-style: normal;
    font-weight: 800;
    width: clamp(138.5px, 14.42616vw, 554px);
    line-height: normal;
  }
  * .nav .pc-nav .pc-nav-container .links-container {
    display: flex;
    align-items: center;
  }
  * .nav .pc-nav .pc-nav-container .links-container .links {
    display: flex;
    align-items: center;
    margin-right: clamp(32px, 3.33312vw, 128px);
  }
  * .nav .pc-nav .pc-nav-container .links-container .links .link {
    color: #333333;
    font-family: Raleway;
    font-size: clamp(9px, 0.93744vw, 36px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-right: clamp(16px, 1.66656vw, 64px);
    transition: 0.3s all;
    cursor: pointer;
  }
  * .nav .pc-nav .pc-nav-container .links-container .links .link:hover {
    color: #007ACC;
  }
  * .nav .pc-nav .pc-nav-container .links-container .links .results {
    margin-right: 0px;
  }
  * .nav .pc-nav .pc-nav-container .links-container .request-btn {
    width: clamp(100px, 10.416vw, 400px);
    height: clamp(22.5px, 2.3436vw, 90px);
    border: none;
    border-radius: 6px;
    background: #007ACC;
    color: #FFF;
    font-family: "Raleway";
    font-size: clamp(9px, 0.93744vw, 36px);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    transition: 0.3s all;
  }
  * .nav .pc-nav .pc-nav-container .links-container .request-btn:hover {
    background: #0069AF;
  }
}/*# sourceMappingURL=navigation-styles.css.map */