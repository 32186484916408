
// Font Links:

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')// Colors:

// Colors:

$brightBlue: #007ACC
$lightGray: #F1F1F1
$darkGray: #333333

// Margins:

$m128: clamp(64px,6.66624vw,256px)
$m64: clamp(32px,3.33312vw,128px)
$m32: clamp(16px,1.66656vw,64px)
$m16: clamp(8px,0.83328vw,32px)
$m8: clamp(4px,0.41664vw,16px)

$m128mob: clamp(64px,29.90592vw,256px)
$m64mob: clamp(32px,3.33312vw,128px)
$m32mob: clamp(16px,7.47648vw,64px)
$m16mob: clamp(8px,3.73824vw,32px)
$m8mob: clamp(4px,1.86912vw,16px)

// Fonts:

$f48: clamp(24px,2.49984vw,96px)
$f36: clamp(18px,1.87488vw,72px)
$f28: clamp(14px,1.45824vw,56px)
$f25: clamp(12.5px,1.302vw,50px)
$f24: clamp(12px,1.24992vw,48px)
$f22: clamp(11px,1.1457600000000001vw,44px)
$f21: clamp(10.5px,1.09368vw,42px)
$f16: clamp(8px,0.83328vw,32px)

// Percents:

$container: 86.45833333333333%

*
    margin: 0
    padding: 0
    box-sizing: border-box

    body
        width: 100%
        height: auto

        .container-box
            width: $container
            margin: auto
            height: auto
            margin-top: $m64mob

            .main-content
                width: 100%
                height: auto
                display: flex
                align-items: center
                flex-direction: column

                .additional-text
                    display: flex
                    flex-direction: column

                    .mini-text
                        font-style: normal
                        font-weight: 600
                        line-height: normal
                        font-family: 'Raleway', sans-serif

                    .line
                        width: 100%
                        height: 1px
                        background-color: $brightBlue
                        margin-top: $m16mob

                .heading
                    text-align: center
                    margin: auto
                    font-size: $m32mob
                    margin-top: $m32mob

                .paragraph
                    font-family: 'Open Sans'
                    font-size: $m16mob
                    text-align: center
                    font-style: normal
                    font-weight: 400
                    line-height: normal
                    margin-top: $m32mob

                .illustration
                    width: clamp(179.5px,83.87675999999999vw,718px)
                    height: clamp(95.5px,44.62524vw,382px)
                    margin-top: $m64mob

                .consult-button
                    width: clamp(145px,67.7556vw,580px)
                    height: clamp(28px,13.083839999999999vw,112px)
                    border: none
                    border-radius: 4px
                    margin-top: clamp(32px,14.95296vw,128px)
                    color: #FFF
                    font-family: Raleway
                    font-size: $m16mob
                    font-style: normal
                    font-weight: 700
                    line-height: normal
                    background-color: $brightBlue

                .more-button
                    width: clamp(145px,67.7556vw,580px)
                    height: clamp(28px,13.083839999999999vw,112px)
                    border: 3px solid $brightBlue
                    border-radius: 4px
                    margin-top: $m32mob
                    color: $brightBlue
                    font-family: Raleway
                    display: flex
                    justify-content: center
                    align-items: center
                    font-size: $m16mob
                    font-style: normal
                    font-weight: 700
                    line-height: normal
                    background-color: transparent

                .arrow
                    margin-top: $m32mob

            .main-content-pc
                display: none

            .arrow
                display: none

@media screen and (min-width: 600px) and (max-width: 1024px)

    *

        body
            width: 100%
            height: auto

            .main-screen
                width: 100%
                height: auto
                display: flex
                flex-direction: column

                .container-box
                    width: $container
                    height: auto
                    margin: auto
                    display: flex
                    flex-direction: column
                    align-items: center
                    margin-top: clamp( 64px,14.398200224971879vw,256px)

                    .additional-text

                        .mini-text
                            font-size: clamp(16px,3.5995500562429696vw,64px) !important

                    .heading
                        margin-top: clamp(32px,7.199100112485939vw,128px)

                    .illustration
                        margin-top: clamp(45px,10.123734533183352vw,180px)

@media screen and  (min-width: 1200px)

    *

        body
            width: 100%
            height: auto

            .main-screen
                width: 100%
                height: 100vh
                display: flex
                flex-direction: column

                .container-box
                    width: $container
                    height: auto
                    margin: auto
                    display: flex
                    flex-direction: column
                    align-items: center
                    margin-top: clamp(125px,13.02vw,500px)

                    .main-content
                        display: none

                    .main-content-pc
                        width: 100%
                        height: auto
                        display: flex
                        justify-content: space-between
                        align-items: center

                        .text-content
                            width: auto
                            height: auto
                            display: flex
                            flex-direction: column

                            .additional-text
                                display: flex
                                align-items: center

                                .mini-text
                                    color: $brightBlue
                                    font-family: Raleway
                                    font-size: $f21
                                    font-style: normal
                                    font-weight: 600
                                    line-height: normal
                                    margin-left: $m16

                                .line
                                    width: clamp(1.5px,0.15624vw,6px)
                                    height: clamp(27px,2.81232vw,108px)
                                    background-color: $brightBlue

                            .heading
                                color: $darkGray
                                font-family: Raleway
                                font-size: $f48
                                text-transform: uppercase
                                font-style: normal
                                font-weight: 800
                                line-height: normal
                                margin-top: $m64

                            .paragraph
                                width: 60%
                                color: $darkGray
                                font-family: Open Sans
                                font-size: $f22
                                font-style: normal
                                font-weight: 400
                                line-height: normal
                                margin-top: $m32

                            .buttons
                                display: flex
                                align-items: center
                                margin-top: $m64

                                .main-button, .more-button
                                    width: clamp(145px,15.103200000000001vw,580px)
                                    height: clamp(28px,2.91648vw,112px)
                                    color: #FFF
                                    font-family: "Raleway"
                                    font-size: clamp(10.5px,1.09368vw,42px)
                                    font-style: normal
                                    font-weight: 700
                                    line-height: normal
                                    border-radius: 6px
                                    border: none

                                .main-button
                                    background-color: $brightBlue
                                    transition: 0.3s all
                                    cursor: pointer
                                    &:hover
                                        background: #0069AF

                                .more-button
                                    border: 4px solid $brightBlue
                                    background-color: transparent
                                    color: $brightBlue
                                    margin-left: $m32
                                    display: flex
                                    align-items: center
                                    justify-content: center
                                    transition: 0.3s all
                                    &:hover
                                        background-color: $brightBlue
                                        color: $lightGray

                        .illustration
                            width: clamp(300px,31.248vw,1200px)
                            height: clamp(200px,20.832vw,800px)

                .arrow
                    display: flex
                    padding-top: $m128
                    width: clamp(265px,27.6024vw,1060px)
                    height: clamp(80px,8.3328vw,320px)
