@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
* body {
  width: 100%;
  height: auto;
}
* body .realisation-screen {
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* body .realisation-screen .container {
  width: 86.4583333333%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
* body .realisation-screen .container .additional-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
* body .realisation-screen .container .additional-text .mini-text {
  font-style: normal;
  font-weight: 600;
  color: #007ACC;
  font-size: clamp(8px, 3.73824vw, 32px);
  line-height: normal;
  font-family: "Raleway", sans-serif;
  margin-top: clamp(32px, 14.95296vw, 128px);
}
* body .realisation-screen .container .additional-text .line {
  width: 100%;
  height: 1px;
  background-color: #007ACC;
  margin-top: 0px;
}
* body .realisation-screen .container .heading {
  width: 85%;
  text-align: center;
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* body .realisation-screen .container .stars {
  display: flex;
  flex-direction: column;
  align-items: center;
}
* body .realisation-screen .container .stars .star-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: clamp(32px, 14.95296vw, 128px);
}
* body .realisation-screen .container .stars .star-container .star-paragraph {
  text-align: center;
  margin-top: clamp(8px, 3.73824vw, 32px);
  color: #333333;
  text-align: center;
  font-family: Open Sans;
  font-size: clamp(8px, 3.73824vw, 32px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
* body .realisation-screen .container .illustration {
  width: 100%;
  height: clamp(122.5px, 57.2418vw, 490px);
  margin-top: clamp(32px, 14.95296vw, 128px);
}
* body .realisation-screen .container-box-c {
  display: none;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  * body {
    width: 100%;
    height: auto;
  }
}
@media screen and (min-width: 1200px) {
  * body {
    width: 100%;
    height: auto;
  }
  * body .realisation-screen {
    width: 100%;
    height: auto;
    margin-top: clamp(64px, 6.66624vw, 256px);
  }
  * body .realisation-screen .container {
    display: none;
  }
  * body .realisation-screen .container-box-c {
    width: 86.4583333333%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin: auto;
  }
  * body .realisation-screen .container-box-c .additional-text {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  * body .realisation-screen .container-box-c .additional-text .mini-text {
    color: #007ACC;
    font-family: Raleway;
    font-size: clamp(10.5px, 1.09368vw, 42px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: clamp(8px, 0.83328vw, 32px);
  }
  * body .realisation-screen .container-box-c .additional-text .line {
    width: clamp(1.5px, 0.15624vw, 6px);
    height: clamp(27px, 2.81232vw, 108px);
    background-color: #007ACC;
  }
  * body .realisation-screen .container-box-c .heading {
    width: 50%;
    color: #333333;
    text-align: center;
    font-family: Raleway;
    font-size: clamp(18px, 1.87488vw, 72px);
    font-style: normal;
    font-weight: 800;
    line-height: 140.4%;
    text-transform: uppercase;
    margin-top: clamp(16px, 1.66656vw, 64px);
  }
  * body .realisation-screen .container-box-c .content {
    width: 100%;
    display: flex;
    margin: auto;
    margin-top: clamp(64px, 6.66624vw, 256px);
  }
  * body .realisation-screen .container-box-c .content .text-content {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  * body .realisation-screen .container-box-c .content .text-content .star-container {
    display: flex;
    align-items: center;
    margin-top: clamp(16px, 1.66656vw, 64px);
  }
  * body .realisation-screen .container-box-c .content .text-content .star-white {
    width: clamp(17.5px, 1.8228vw, 70px);
    height: clamp(17.5px, 1.8228vw, 70px);
  }
  * body .realisation-screen .container-box-c .content .text-content .star-paragraph {
    width: 70%;
    color: #333333;
    font-family: Open Sans;
    font-size: clamp(11px, 1.14576vw, 44px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: clamp(16px, 1.66656vw, 64px);
  }
  * body .realisation-screen .container-box-c .content .illustration {
    width: clamp(329.5px, 34.32072vw, 1318px);
    height: clamp(224.5px, 23.38392vw, 898px);
  }
}/*# sourceMappingURL=realisation-styles.css.map */