@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
* html {
  overflow-x: hidden !important;
}
* html body {
  width: 100%;
  height: auto;
  overflow-x: hidden !important;
}
* html body h1 {
  color: #333333;
  font-family: "Raleway", sans-serif;
  font-size: clamp(16px, 7.47648vw, 64px);
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
* html body h2 {
  color: #333333;
  font-family: "Raleway", sans-serif;
  font-size: clamp(12px, 5.60736vw, 48px);
  font-style: normal;
  font-weight: 800;
  line-height: 140.4%;
  text-transform: uppercase;
}
* html body h3 {
  color: #333333;
  text-align: center;
  font-family: Open Sans;
  font-size: clamp(9px, 4.20552vw, 36px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
* html body p {
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-size: clamp(7px, 3.27096vw, 28px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
* html body span {
  color: #007ACC;
}
* html body a, * html body button {
  cursor: pointer;
}/*# sourceMappingURL=general-styles.css.map */