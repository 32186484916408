@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
* body {
  width: 100%;
  height: auto;
}
* body .last-screen {
  margin-top: clamp(50px, 23.364vw, 200px);
}
* body .last-screen .container {
  width: 86.4583333333%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
* body .last-screen .container .additional-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
* body .last-screen .container .additional-text .mini-text {
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: "Raleway", sans-serif;
}
* body .last-screen .container .additional-text .line {
  width: 100%;
  height: 1px;
  background-color: #007ACC;
}
* body .last-screen .container .heading {
  width: 90%;
  color: #333333;
  text-align: center;
  margin: auto;
  margin-top: clamp(16px, 7.47648vw, 64px);
  font-family: Raleway;
  font-size: clamp(12px, 5.60736vw, 48px);
  font-style: normal;
  font-weight: 800;
  line-height: 140.4%;
  text-transform: uppercase;
}
* body .last-screen .container .taxes-monitoring-one {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
* body .last-screen .container .taxes-monitoring-one .star-container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: clamp(32px, 14.95296vw, 128px);
}
* body .last-screen .container .taxes-monitoring-one .star-container .star-text {
  color: #333333;
  text-align: center;
  font-family: Open Sans;
  font-size: clamp(8px, 3.73824vw, 32px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: clamp(8px, 3.73824vw, 32px);
}
* body .last-screen .container .taxes-monitoring-one .illustration-one {
  width: clamp(178.5px, 83.40948vw, 714px);
  height: clamp(122.5px, 57.2418vw, 490px);
  margin-top: clamp(32px, 14.95296vw, 128px);
}
* body .last-screen .container .line-2 {
  width: 100%;
  height: 1px;
  background-color: #007ACC;
}
* body .last-screen .container .taxes-monitoring-two {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
* body .last-screen .container .taxes-monitoring-two .star-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: clamp(32px, 14.95296vw, 128px);
}
* body .last-screen .container .taxes-monitoring-two .star-container .star-text {
  color: #333333;
  text-align: center;
  font-family: Open Sans;
  font-size: clamp(8px, 3.73824vw, 32px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: clamp(8px, 3.73824vw, 32px);
}
* body .last-screen .container .taxes-monitoring-two .illustration-two {
  width: clamp(178.5px, 83.40948vw, 714px);
  height: clamp(122.5px, 57.2418vw, 490px);
  margin-top: clamp(32px, 14.95296vw, 128px);
}
* body .last-screen .container-box-c {
  display: none;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  * body {
    width: 100%;
    height: auto;
  }
  * body .heading {
    margin-top: clamp(16px, 3.5995500562vw, 64px) !important;
  }
}
@media screen and (min-width: 1200px) {
  * body {
    width: 100%;
    height: auto;
  }
  * body .last-screen {
    width: 100%;
    height: auto;
    margin-top: clamp(64px, 6.66624vw, 256px);
  }
  * body .last-screen .container {
    display: none;
  }
  * body .last-screen .container-box-c {
    width: 86.4583333333%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
  }
  * body .last-screen .container-box-c .additional-text {
    display: flex;
    align-items: center;
  }
  * body .last-screen .container-box-c .additional-text .mini-text {
    color: #007ACC;
    font-family: Raleway;
    font-size: clamp(10.5px, 1.09368vw, 42px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: clamp(8px, 0.83328vw, 32px);
    margin-left: clamp(8px, 0.83328vw, 32px);
  }
  * body .last-screen .container-box-c .additional-text .line {
    width: clamp(1.5px, 0.15624vw, 6px);
    height: clamp(27px, 2.81232vw, 108px);
    background-color: #007ACC;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: auto;
  }
  * body .last-screen .container-box-c .heading {
    width: 60%;
    margin: auto;
    color: #333333;
    text-align: center;
    font-family: Raleway;
    font-size: clamp(18px, 1.87488vw, 72px);
    font-style: normal;
    font-weight: 800;
    line-height: 140.4%;
    text-transform: uppercase;
    margin-top: clamp(16px, 1.66656vw, 64px);
  }
  * body .last-screen .container-box-c .screen-1 {
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    margin: auto;
    display: flex;
    block-size: -moz-fit-content;
    block-size: fit-content;
    flex-direction: row;
    justify-content: center;
    margin-top: clamp(64px, 6.66624vw, 256px);
  }
  * body .last-screen .container-box-c .screen-1 .content {
    width: -moz-fit-content !important;
    width: fit-content !important;
    block-size: -moz-fit-content;
    block-size: fit-content;
    margin: auto;
    display: flex;
  }
  * body .last-screen .container-box-c .screen-1 .content .illustration-one {
    width: clamp(314px, 32.70624vw, 1256px);
  }
  * body .last-screen .container-box-c .screen-1 .content .text-containez {
    width: clamp(368.5px, 38.38296vw, 1474px);
    block-size: -moz-fit-content;
    block-size: fit-content;
    display: flex;
    flex-direction: column;
    margin-left: clamp(32px, 3.33312vw, 128px);
  }
  * body .last-screen .container-box-c .screen-1 .content .text-containez .star {
    width: clamp(17.5px, 1.8228vw, 70px);
    height: clamp(17.5px, 1.8228vw, 70px);
  }
  * body .last-screen .container-box-c .screen-1 .content .text-containez .text-tab {
    width: clamp(331px, 34.47696vw, 1324px);
    block-size: -moz-fit-content !important;
    block-size: fit-content !important;
    display: flex;
    align-items: center;
    margin-top: clamp(16px, 1.66656vw, 64px);
  }
  * body .last-screen .container-box-c .screen-1 .content .text-containez .text-tab .paragraph-text {
    width: -moz-fit-content;
    width: fit-content;
    margin-left: clamp(8px, 0.83328vw, 32px);
    margin-top: clamp(8px, 0.83328vw, 32px);
    color: #333333;
    font-family: Open Sans;
    font-size: clamp(9.5px, 0.98952vw, 38px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    flex-shrink: 0 !important;
  }
  * body .last-screen .container-box-c .line-2 {
    width: 100%;
    height: 2px;
    background-color: #007ACC;
    margin-top: clamp(32px, 3.33312vw, 128px);
  }
  * body .last-screen .container-box-c .screen-2 {
    width: 85%;
    height: auto;
    margin: auto;
    display: flex;
    block-size: -moz-fit-content;
    block-size: fit-content;
    flex-direction: row;
    justify-content: space-between;
    margin-top: clamp(64px, 6.66624vw, 256px);
  }
  * body .last-screen .container-box-c .screen-2 .content {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
  * body .last-screen .container-box-c .screen-2 .content .text-containez {
    display: flex;
    flex-direction: column;
    margin-right: clamp(32px, 3.33312vw, 128px);
  }
  * body .last-screen .container-box-c .screen-2 .content .text-containez .text-tab {
    width: clamp(295px, 30.7272vw, 1180px);
    display: flex;
    align-items: center;
    margin-top: clamp(16px, 1.66656vw, 64px);
  }
  * body .last-screen .container-box-c .screen-2 .content .text-containez .text-tab .star {
    width: clamp(17.5px, 1.8228vw, 70px);
    height: clamp(17.5px, 1.8228vw, 70px);
  }
  * body .last-screen .container-box-c .screen-2 .content .text-containez .text-tab .paragraph-text {
    width: -moz-fit-content;
    width: fit-content;
    margin-left: clamp(8px, 0.83328vw, 32px);
    margin-top: clamp(8px, 0.83328vw, 32px);
    color: #333333;
    font-family: Open Sans;
    font-size: clamp(9.5px, 0.98952vw, 38px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    flex-shrink: 0 !important;
  }
  * body .last-screen .container-box-c .screen-2 .content .illustration-two {
    width: clamp(314px, 32.70624vw, 1256px);
  }
}/*# sourceMappingURL=last-styles.css.map */