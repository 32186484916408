@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
* body {
  width: 100%;
  height: auto;
}
* body .features-screen {
  background-color: #007ACC;
  width: 100%;
  height: auto;
  margin-top: clamp(50px, 23.364vw, 200px);
}
* body .features-screen .container {
  width: 86.4583333333%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
* body .features-screen .container .additional-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
* body .features-screen .container .additional-text .mini-text {
  font-style: normal;
  font-weight: 600;
  color: #F1F1F1;
  font-size: clamp(8px, 3.73824vw, 32px);
  line-height: normal;
  font-family: "Raleway", sans-serif;
  margin-top: clamp(32px, 14.95296vw, 128px);
}
* body .features-screen .container .additional-text .line {
  width: 100%;
  height: 1px;
  background-color: #F1F1F1;
  margin-top: 0px;
}
* body .features-screen .container .heading {
  color: #F1F1F1;
  text-align: center;
  font-family: Raleway;
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
}
* body .features-screen .container .stars {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
* body .features-screen .container .stars .star-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
* body .features-screen .container .stars .star-container .star-white {
  margin-top: clamp(32px, 14.95296vw, 128px);
}
* body .features-screen .container .stars .star-container .star-paragraph {
  text-align: center;
  color: #FFF;
  text-align: center;
  font-family: Open Sans;
  font-size: clamp(8px, 3.73824vw, 32px);
  margin-top: clamp(8px, 3.73824vw, 32px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
* body .features-screen .container .request-btn {
  width: clamp(145px, 67.7556vw, 580px);
  height: clamp(28px, 13.08384vw, 112px);
  flex-shrink: 0;
  color: #007ACC;
  font-family: Raleway;
  font-size: clamp(8px, 3.73824vw, 32px);
  font-style: normal;
  font-weight: 700;
  margin-top: clamp(16px, 7.47648vw, 64px);
  margin-bottom: clamp(32px, 14.95296vw, 128px);
  line-height: normal;
  border: none;
  border-radius: 4px;
}
* body .features-screen .container-box-pc {
  display: none;
}

@media screen and (min-width: 1200px) {
  * body {
    width: 100%;
    height: auto;
  }
  * body .features-screen {
    background: #007ACC;
    background: url("../features/BackgroundEarth.png") center;
  }
  * body .features-screen .container {
    display: none !important;
  }
  * body .features-screen .container-box-pc {
    width: 86.4583333333%;
    margin: auto;
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }
  * body .features-screen .container-box-pc .additional-text {
    display: flex;
    align-items: center;
    margin-top: clamp(32px, 3.33312vw, 128px);
  }
  * body .features-screen .container-box-pc .additional-text .line {
    width: clamp(1.5px, 0.15624vw, 6px);
    height: clamp(27px, 2.81232vw, 108px);
    background-color: #F1F1F1;
  }
  * body .features-screen .container-box-pc .additional-text .mini-text {
    color: #F1F1F1;
    font-family: Raleway;
    font-size: clamp(10.5px, 1.09368vw, 42px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: clamp(8px, 0.83328vw, 32px);
  }
  * body .features-screen .container-box-pc .heading {
    width: 40%;
    text-align: center;
    color: #F1F1F1;
    text-align: center;
    font-family: Raleway;
    font-size: clamp(18px, 1.87488vw, 72px);
    font-style: normal;
    font-weight: 800;
    line-height: 140.4%;
    text-transform: uppercase;
    margin-top: clamp(16px, 1.66656vw, 64px);
  }
  * body .features-screen .container-box-pc .stars {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }
  * body .features-screen .container-box-pc .stars .star-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: clamp(32px, 3.33312vw, 128px);
  }
  * body .features-screen .container-box-pc .stars .star-container .star-paragraph {
    width: 60%;
    color: #FFF;
    text-align: center;
    font-family: Open Sans;
    font-size: clamp(10.5px, 1.09368vw, 42px);
    font-style: normal;
    margin-top: clamp(8px, 0.83328vw, 32px);
    font-weight: 500;
    line-height: normal;
  }
  * body .features-screen .container-box-pc .request {
    width: clamp(145px, 15.1032vw, 580px);
    height: clamp(28px, 2.91648vw, 112px);
    color: #007ACC;
    font-family: Raleway;
    font-size: clamp(10.5px, 1.09368vw, 42px);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
    border-radius: 6px;
    margin-top: clamp(32px, 3.33312vw, 128px);
    margin-bottom: clamp(32px, 3.33312vw, 128px);
    transition: 0.3s all;
  }
  * body .features-screen .container-box-pc .request:hover {
    background-color: #E1E1E1;
  }
}/*# sourceMappingURL=features-styles.css.map */