@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
* body {
  width: 100%;
  height: auto;
}
* body .requirements-screen {
  margin-top: clamp(50px, 23.364vw, 200px);
}
* body .requirements-screen .container-box {
  width: 86.4583333333%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}
* body .requirements-screen .container-box .heading {
  color: #333333;
  text-align: center;
  margin: auto;
  margin-top: clamp(16px, 7.47648vw, 64px);
  font-family: Raleway;
  font-size: clamp(12px, 5.60736vw, 48px);
  font-style: normal;
  font-weight: 800;
  line-height: 140.4%;
  text-transform: uppercase;
}
* body .requirements-screen .container-box .star {
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* body .requirements-screen .container-box .paragraph {
  color: #333;
  text-align: center;
  font-family: "Open Sans";
  font-size: clamp(7px, 3.27096vw, 28px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* body .requirements-screen .container-box .box {
  width: 100%;
  height: auto;
  border-radius: 10px;
  background: #F1F1F1;
  box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: clamp(32px, 14.95296vw, 128px);
}
* body .requirements-screen .container-box .box .box-heading-c {
  display: flex;
  flex-direction: column;
  align-items: center;
}
* body .requirements-screen .container-box .box .box-heading-c .number-container {
  width: clamp(21px, 9.81288vw, 84px);
  height: clamp(21px, 9.81288vw, 84px);
  background-color: #007ACC;
  border-radius: 100px;
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* body .requirements-screen .container-box .box .box-heading-c .number-container .number {
  color: #FFF;
  text-align: center;
  font-family: "Open Sans";
  margin-top: clamp(2px, 0.93456vw, 8px);
  font-size: clamp(12px, 5.60736vw, 48px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
* body .requirements-screen .container-box .box .box-heading-c .box-heading {
  color: #333333;
  text-align: center;
  font-family: "Open Sans";
  margin-top: clamp(4px, 1.86912vw, 16px);
  font-size: clamp(9px, 4.20552vw, 36px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
* body .requirements-screen .container-box .box .box-text {
  width: 70%;
  font-family: Open Sans;
  font-size: clamp(8.5px, 3.97188vw, 34px);
  font-style: normal;
  text-align: center;
  font-weight: 400;
  margin-top: clamp(8px, 3.73824vw, 32px);
  margin-bottom: clamp(16px, 7.47648vw, 64px);
  line-height: normal;
}
* body .requirements-screen .container-box .request {
  width: clamp(145px, 67.7556vw, 580px);
  height: clamp(28px, 13.08384vw, 112px);
  background-color: #007ACC;
  color: #FFF;
  font-family: Raleway;
  font-size: clamp(8px, 3.73824vw, 32px);
  font-style: normal;
  font-weight: 700;
  border: none;
  border-radius: 4px;
  margin-top: clamp(32px, 14.95296vw, 128px);
  line-height: normal;
}
* body .requirements-screen .container-box-pc {
  display: none;
}

@media screen and (min-width: 1200px) {
  * body {
    width: 100%;
    height: auto;
  }
  * body .requirements-screen {
    width: 100%;
    height: auto;
    margin-top: clamp(128px, 13.33248vw, 512px);
  }
  * body .requirements-screen .container-box {
    display: none;
  }
  * body .requirements-screen .container-box-pc {
    width: 86.4583333333%;
    height: auto;
    display: flex;
    margin: auto;
    flex-direction: column;
  }
  * body .requirements-screen .container-box-pc .heading {
    width: 50%;
    margin: auto;
    font-size: clamp(18px, 1.87488vw, 72px);
    text-align: center;
    color: #333333;
  }
  * body .requirements-screen .container-box-pc .tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: clamp(32px, 3.33312vw, 128px);
  }
  * body .requirements-screen .container-box-pc .tab .tab-text {
    width: 60%;
    color: #333333;
    text-align: center;
    margin-top: clamp(16px, 1.66656vw, 64px);
    font-family: Open Sans;
    font-size: clamp(11px, 1.14576vw, 44px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  * body .requirements-screen .container-box-pc .boxes {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: clamp(32px, 3.33312vw, 128px);
  }
  * body .requirements-screen .container-box-pc .boxes .box {
    width: clamp(255px, 26.5608vw, 1020px);
    height: clamp(108.5px, 11.30136vw, 434px);
    border-radius: 10px;
    background: #F1F1F1;
    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.1);
  }
  * body .requirements-screen .container-box-pc .boxes .box .box-heading-c {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: clamp(8px, 0.83328vw, 32px);
    margin-top: clamp(8px, 0.83328vw, 32px);
  }
  * body .requirements-screen .container-box-pc .boxes .box .box-heading-c .number-container {
    width: clamp(25.5px, 2.65608vw, 102px);
    height: clamp(25.5px, 2.65608vw, 102px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #007ACC;
    border-radius: 100px;
  }
  * body .requirements-screen .container-box-pc .boxes .box .box-heading-c .number-container .number {
    color: #FFF;
    text-align: center;
    font-family: Open Sans;
    font-size: clamp(12.5px, 1.302vw, 50px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 8px;
  }
  * body .requirements-screen .container-box-pc .boxes .box .box-heading-c .box-heading {
    color: #333333;
    text-align: center;
    font-family: Open Sans;
    font-size: clamp(12px, 1.24992vw, 48px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: clamp(8px, 0.83328vw, 32px);
  }
  * body .requirements-screen .container-box-pc .boxes .box .box-text {
    width: 80%;
    margin: auto;
    text-align: left;
    margin-left: clamp(8px, 0.83328vw, 32px);
    margin-top: clamp(16px, 1.66656vw, 64px);
    color: #333333;
    font-family: Open Sans;
    font-size: clamp(11px, 1.14576vw, 44px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  * body .requirements-screen .container-box-pc .request {
    width: clamp(142.5px, 14.8428vw, 570px);
    height: clamp(28px, 2.91648vw, 112px);
    border-radius: 6px;
    background: #007ACC;
    color: #FFF;
    font-family: Raleway;
    font-size: clamp(12px, 1.24992vw, 48px);
    border: none;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: clamp(32px, 3.33312vw, 128px) !important;
    margin: auto;
    transition: 0.3s all;
  }
  * body .requirements-screen .container-box-pc .request:hover {
    background: #0069AF;
  }
}/*# sourceMappingURL=requirements-styles.css.map */