
// Font Links:

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')// Colors:

// Colors:

$brightBlue: #007ACC
$lightGray: #F1F1F1
$darkGray: #333333

// Margins:

$m128: clamp(64px,6.66624vw,256px)
$m64: clamp(32px,3.33312vw,128px)
$m32: clamp(16px,1.66656vw,64px)
$m16: clamp(8px,0.83328vw,32px)
$m8: clamp(4px,0.41664vw,16px)

$m128mob: clamp(64px,29.90592vw,256px)
$m64mob: clamp(32px,14.95296vw,128px)
$m32mob: clamp(16px,7.47648vw,64px)
$m16mob: clamp(8px,3.73824vw,32px)
$m8mob: clamp(4px,1.86912vw,16px)

// Fonts:

$f48: clamp(24px,2.49984vw,96px)
$f36: clamp(18px,1.87488vw,72px)
$f28: clamp(14px,1.45824vw,56px)
$f25: clamp(12.5px,1.302vw,50px)
$f24: clamp(12px,1.24992vw,48px)
$f22: clamp(11px,1.1457600000000001vw,44px)
$f21: clamp(10.5px,1.09368vw,42px)
$f16: clamp(8px,0.83328vw,32px)

// Percents:

$container: 86.45833333333333%

*
    margin: 0
    padding: 0
    box-sizing: border-box

    body
        width: 100%
        height: auto

        .roadmap-screen
            margin-top: clamp(50px,23.363999999999997vw,200px)

            .container
                width: $container
                height: auto
                display: flex
                flex-direction: column
                align-items: center

                .additional-text
                    width: 100%
                    display: flex
                    flex-direction: column
                    align-items: center

                    .mini-text
                        font-style: normal
                        font-weight: 600
                        font-size: clamp(8px,3.73824vw,32px)
                        line-height: normal
                        font-family: 'Raleway', sans-serif

                    .line
                        width: 100%
                        height: 1px
                        background-color: $brightBlue

                .heading
                    color: $darkGray
                    text-align: center
                    margin: auto
                    margin-top: $m32mob
                    font-family: Raleway
                    font-size: clamp(12px,5.60736vw,48px)
                    font-style: normal
                    font-weight: 800
                    line-height: 140.4%
                    text-transform: uppercase

                .roadmap-container
                    display: flex
                    flex-direction: column
                    align-items: center

                    .roadmap-step
                        display: flex
                        flex-direction: column
                        align-items: center
                        margin-top: $m64mob

                        .number
                            color: $brightBlue
                            font-family: Open Sans
                            font-size: clamp(24px,11.21472vw,96px)
                            font-style: normal
                            font-weight: 800
                            line-height: normal

                        .paragraph
                            color: $darkGray
                            text-align: center
                            font-family: Open Sans
                            font-size: $m16mob
                            text-align: center
                            font-style: normal
                            font-weight: 400
                            line-height: normal
                            margin-top: $m16mob

                        .roadmap-illustration
                            margin-top: $m32mob

            .container-box-c
                display: none

@media screen and  (min-width: 1200px)

    *

        body
            width: 100%
            height: auto

            .roadmap-screen
                width: 100%
                height: auto
                margin-top: clamp(125px,13.02vw,500px)

                .container
                    display: none

                .container-box-c
                    width: $container
                    height: auto
                    flex-direction: column
                    display: flex
                    align-items: center
                    margin: auto

                    .additional-text
                        display: flex
                        align-items: center

                        .mini-text
                            color: $brightBlue
                            font-family: Raleway
                            font-size: $f21
                            font-style: normal
                            font-weight: 600
                            line-height: normal
                            margin-left: $m16

                        .line
                            width: clamp(1.5px,0.15624vw,6px)
                            height: clamp(27px,2.81232vw,108px)
                            background-color: $brightBlue

                    .heading
                        color: $darkGray
                        text-align: center
                        font-family: Raleway
                        font-size: $f36
                        font-style: normal
                        font-weight: 800
                        line-height: 140.4%
                        text-transform: uppercase
                        margin-top: $m32

                    .roadmap
                        margin-top: $m128
                        height: clamp(235px,24.4776vw,940px)
                        user-select: none

                    .roadmap-container
                        width: 100%
                        display: flex
                        flex-direction: column
                        align-items: center
                        margin-top: $m128

                        .roadmap-steps-container
                            width: 100%
                            display: flex
                            flex-direction: row
                            align-items: center
                            justify-content: space-between

                            .roadmap-step
                                display: flex
                                flex-direction: column
                                align-items: center

                                .number
                                    color: $brightBlue
                                    font-family: Open Sans
                                    font-size: clamp(20px,2.0832vw,80px)
                                    font-style: normal
                                    font-weight: 800
                                    line-height: normal

                                .paragraph
                                    width: 50%
                                    color: $darkGray
                                    text-align: center
                                    font-family: Open Sans
                                    font-size: $f16
                                    font-style: normal
                                    font-weight: 400
                                    line-height: normal

                                .roadmap-illustration
                                    height: clamp(130px,13.5408vw,520px)
                                    rotate: (-90deg)
