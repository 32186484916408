
// Font Links:

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')// Colors:

// Colors:

$brightBlue: #007ACC
$lightGray: #F1F1F1
$darkGray: #333333

// Margins:

$m128: clamp(64px,6.66624vw,256px)
$m64: clamp(32px,3.33312vw,128px)
$m32: clamp(16px,1.66656vw,64px)
$m16: clamp(8px,0.83328vw,32px)
$m8: clamp(4px,0.41664vw,16px)

$m128mob: clamp(64px,29.90592vw,256px)
$m64mob: clamp(32px,14.95296vw,128px)
$m32mob: clamp(16px,7.47648vw,64px)
$m16mob: clamp(8px,3.73824vw,32px)
$m8mob: clamp(4px,1.86912vw,16px)

// Fonts:

$f48: clamp(24px,2.49984vw,96px)
$f36: clamp(18px,1.87488vw,72px)
$f28: clamp(14px,1.45824vw,56px)
$f25: clamp(12.5px,1.302vw,50px)
$f24: clamp(12px,1.24992vw,48px)
$f22: clamp(11px,1.1457600000000001vw,44px)
$f21: clamp(10.5px,1.09368vw,42px)
$f16: clamp(8px,0.83328vw,32px)

// Percents:

$container: 86.45833333333333%

*
    margin: 0
    padding: 0
    box-sizing: border-box

    body
        width: 100%
        height: auto

        .results-screen
            margin-top: clamp(50px,23.363999999999997vw,200px)

            .container
                width: $container
                height: auto
                display: flex
                flex-direction: column
                align-items: center

                .additional-text
                    width: 100%
                    display: flex
                    flex-direction: column
                    align-items: center

                    .mini-text
                        font-style: normal
                        font-weight: 600
                        line-height: normal
                        font-family: 'Raleway', sans-serif

                    .line
                        width: 100%
                        height: 1px
                        background-color: $brightBlue
                        margin-top: 0px

                .heading
                    text-align: center

                .star-tab
                    width: clamp(178.5px,83.40948vw,714px) !important
                    height: clamp(108.5px,50.69988vw,434px)
                    display: flex
                    flex-direction: column
                    align-items: center
                    justify-content: center
                    border-radius: 10px
                    background: #F1F1F1
                    margin-top: $m32mob

                    .star-text
                        color: $darkGray
                        text-align: center
                        font-family: Open Sans
                        font-size: clamp(10.5px,4.90644vw,42px)
                        font-style: normal
                        font-weight: 600
                        margin-top: $m16mob

                .between-line
                    width: 100%
                    height: 1px
                    background-color: $brightBlue
                    margin-top: $m32mob

                .star-tab-m
                    width: 100%
                    height: clamp(157.5px,73.5966vw,630px)
                    display: flex
                    flex-direction: column
                    align-items: center
                    justify-content: center
                    border-radius: 10px
                    background: #F1F1F1
                    margin-top: $m32mob

                    .star-text
                        width: 80%
                        color: $darkGray
                        text-align: center
                        font-family: Open Sans
                        font-size: clamp(10.5px,4.90644vw,42px)
                        font-style: normal
                        font-weight: 600
                        margin-top: $m16mob

                .star-tab-b
                    width: 100%
                    height: clamp(164.5px,76.86756vw,658px)
                    display: flex
                    flex-direction: column
                    align-items: center
                    justify-content: center
                    border-radius: 10px
                    background: #F1F1F1
                    margin-top: $m32mob

                    .star-text
                        width: 90%
                        color: $darkGray
                        text-align: center
                        font-family: Open Sans
                        font-size: clamp(10.5px,4.90644vw,42px)
                        font-style: normal
                        font-weight: 600
                        margin-top: $m16mob

            .container-box-c
                display: none

@media screen and (min-width: 600px) and (max-width: 1024px)

    *

        body
            width: 100%
            height: auto

            .mini-text
                color: $brightBlue

@media screen and  (min-width: 1200px)

    *

        body
            width: 100%
            height: auto

            .results-screen
                width: 100%
                height: auto
                margin-top: clamp(125px,13.02vw,500px)

                .container
                    display: none

                .container-box-c
                    width: $container
                    height: auto
                    margin: auto
                    display: flex
                    flex-direction: column
                    align-items: center

                    .additional-text
                        display: flex
                        align-items: center

                        .mini-text
                            color: $brightBlue
                            font-family: Raleway
                            font-size: $f21
                            font-style: normal
                            font-weight: 600
                            line-height: normal
                            margin-left: $m16

                        .line
                            width: clamp(1.5px,0.15624vw,6px)
                            height: clamp(27px,2.81232vw,108px)
                            background-color: $brightBlue

                    .heading
                        width: 30%
                        color: $darkGray
                        text-align: center
                        font-family: Raleway
                        font-size: $f36
                        font-style: normal
                        font-weight: 800
                        line-height: 140.4%
                        text-transform: uppercase
                        margin-top: $m32

                    .row-one, .row-two, .row-three
                        width: 100%
                        display: flex
                        align-items: center
                        justify-content: space-between
                        margin-top: $m64

                        .star-tab
                            width: clamp(266px,27.70656vw,1064px)
                            height: clamp(108.5px,11.30136vw,434px)
                            border-radius: 10px
                            background: #F1F1F1
                            display: flex
                            flex-direction: column
                            justify-content: center
                            align-items: center
                            box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.06)

                            .star-text
                                color: $darkGray
                                text-align: center
                                font-family: Open Sans
                                font-size: $f25
                                font-style: normal
                                font-weight: 600
                                line-height: 140.4%
                                margin-top: $m32

                        .star-tab-m
                            width: clamp(266px,27.70656vw,1064px)
                            height: clamp(120.5px,12.55128vw,482px)
                            border-radius: 10px
                            background: #F1F1F1
                            display: flex
                            flex-direction: column
                            justify-content: center
                            align-items: center
                            box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.06)

                            .star-text
                                width: 70%
                                color: $darkGray
                                text-align: center
                                font-family: Open Sans
                                font-size: $f22
                                font-style: normal
                                font-weight: 600
                                line-height: 140.4%
                                margin-top: $m32

                        .star-tab-b
                            width: clamp(266px,27.70656vw,1064px)
                            height: clamp(146px,15.20736vw,584px)
                            border-radius: 10px
                            background: #F1F1F1
                            display: flex
                            flex-direction: column
                            justify-content: center
                            align-items: center
                            box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.06)

                            .star-text
                                width: 80%
                                color: $darkGray
                                text-align: center
                                font-family: Open Sans
                                font-size: $f22
                                font-style: normal
                                font-weight: 600
                                line-height: 140.4%
                                margin-top: $m32

                    .line-between
                        width: 100%
                        height: 3px
                        background: rgba(0, 122, 204, 0.50)
                        margin-top: $m64
