
// Font Links:

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')// Colors:

// Colors:

$brightBlue: #007ACC
$lightGray: #F1F1F1
$darkGray: #333333

// Margins:

$m128: clamp(64px,6.66624vw,256px)
$m64: clamp(32px,3.33312vw,128px)
$m32: clamp(16px,1.66656vw,64px)
$m16: clamp(8px,0.83328vw,32px)
$m8: clamp(4px,0.41664vw,16px)

$m128mob: clamp(64px,29.90592vw,256px)
$m64mob: clamp(32px,14.95296vw,128px)
$m32mob: clamp(16px,7.47648vw,64px)
$m16mob: clamp(8px,3.73824vw,32px)
$m8mob: clamp(4px,1.86912vw,16px)

// Fonts:

$f48: clamp(24px,2.49984vw,96px)
$f36: clamp(18px,1.87488vw,72px)
$f28: clamp(14px,1.45824vw,56px)
$f25: clamp(12.5px,1.302vw,50px)
$f24: clamp(12px,1.24992vw,48px)
$f22: clamp(11px,1.1457600000000001vw,44px)
$f21: clamp(10.5px,1.09368vw,42px)
$f16: clamp(8px,0.83328vw,32px)

// Percents:

$container: 86.45833333333333%

*
    margin: 0
    padding: 0
    box-sizing: border-box

    body
        width: 100%
        height: auto

        .realisation-screen
            margin-top: $m32mob

            .container
                width: $container
                height: auto
                display: flex
                flex-direction: column
                align-items: center

                .additional-text
                    width: 100%
                    display: flex
                    flex-direction: column
                    align-items: center

                    .mini-text
                        font-style: normal
                        font-weight: 600
                        color: $brightBlue
                        font-size: clamp(8px,3.73824vw,32px)
                        line-height: normal
                        font-family: 'Raleway', sans-serif
                        margin-top: $m64mob

                    .line
                        width: 100%
                        height: 1px
                        background-color: $brightBlue
                        margin-top: 0px

                .heading
                    width: 85%
                    text-align: center
                    margin-top: $m32mob

                .stars
                    display: flex
                    flex-direction: column
                    align-items: center

                    .star-container
                        display: flex
                        flex-direction: column
                        align-items: center
                        margin-top: $m64mob

                        .star-paragraph
                            text-align: center
                            margin-top: $m16mob
                            color: $darkGray
                            text-align: center
                            font-family: Open Sans
                            font-size: $m16mob
                            font-style: normal
                            font-weight: 600
                            line-height: normal

                .illustration
                    width: 100%
                    height: clamp(122.5px,57.2418vw,490px)
                    margin-top: $m64mob

            .container-box-c
                display: none

@media screen and (min-width: 600px) and (max-width: 1024px)

    *
        margin: 0
        padding: 0
        box-sizing: border-box

        body
            width: 100%
            height: auto

@media screen and  (min-width: 1200px)

    *

        body
            width: 100%
            height: auto

            .realisation-screen
                width: 100%
                height: auto
                margin-top: $m128

                .container
                    display: none

                .container-box-c
                    width: $container
                    height: auto
                    display: flex
                    align-items: center
                    justify-content: space-between
                    flex-direction: column
                    margin: auto

                    .additional-text
                        display: flex
                        flex-direction: row
                        align-items: center

                        .mini-text
                            color: $brightBlue
                            font-family: Raleway
                            font-size: $f21
                            font-style: normal
                            font-weight: 600
                            line-height: normal
                            margin-left: $m16

                        .line
                            width: clamp(1.5px,0.15624vw,6px)
                            height: clamp(27px,2.81232vw,108px)
                            background-color: $brightBlue

                    .heading
                        width: 50%
                        color: $darkGray
                        text-align: center
                        font-family: Raleway
                        font-size: $f36
                        font-style: normal
                        font-weight: 800
                        line-height: 140.4%
                        text-transform: uppercase
                        margin-top: $m32

                    .content
                        width: 100%
                        display: flex
                        margin: auto
                        margin-top: $m128

                        .text-content
                            width: auto
                            height: auto
                            display: flex
                            flex-direction: column
                            align-items: start

                            .star-container
                                display: flex
                                align-items: center
                                margin-top: $m32

                            .star-white
                                width: clamp(17.5px,1.8228vw,70px)
                                height: clamp(17.5px,1.8228vw,70px)

                            .star-paragraph
                                width: 70%
                                color: $darkGray
                                font-family: Open Sans
                                font-size: $f22
                                font-style: normal
                                font-weight: 600
                                line-height: normal
                                margin-left: $m32

                        .illustration
                            width: clamp(329.5px,34.32072vw,1318px)
                            height: clamp(224.5px,23.38392vw,898px)
